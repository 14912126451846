import { useState, useEffect, MouseEventHandler } from "react";
import { Search, X, LoaderCircle, MapPin, ChevronDown } from "lucide-react";
import { useDebounce } from "use-debounce";
import { Form, useFetcher } from "react-router";
import { API_ROUTES, ROUTES } from "~/services/const";
import { Location } from "~/services/location";

const SEARCH_DELAY = 500;
const RESULTS_EMPTY_STATE: Location[] = [];

const LoadingState = () => (
  <div className="text-center py-6 px-10 sm:py-12 border rounded">
    <LoaderCircle className="animate-spin mx-auto text-gray-400 w-16 h-16 mb-4" />
    <h3 className="text-lg font-semibold text-gray-900 mb-2">A pesquisar...</h3>
    <p className="text-gray-600">Por favor, aguarde enquanto procuramos os resultados.</p>
  </div>
);

const EmptyState = () => (
  <div className="text-center py-6 px-10 sm:py-12 border rounded">
    <Search className="mx-auto text-gray-400 w-16 h-16 mb-4" />
    <h3 className="text-lg font-semibold text-gray-900 mb-2">Pesquisa pelo nome da tua cidade</h3>
    <p className="text-gray-600">
      Vamos mostrar-te os espetáculos que vão acontecer nas salas mais próximas da localização que escolheres.
    </p>
  </div>
);

const NoResultsState = () => (
  <div className="text-center py-6 px-10 sm:py-12 border rounded">
    <Search className="mx-auto text-gray-400 w-16 h-16 mb-4" />
    <h3 className="text-lg font-semibold text-gray-900 mb-2">Nenhum resultado encontrado</h3>
    <p className="text-gray-600">Não encontramos nenhuma localização com esse termo de pesquisa.</p>
    <p className="text-gray-600">Tenta usar o nome de uma cidade próxima, por exemplo.</p>
  </div>
);

const ErrorState = () => (
  <div className="text-center py-6 px-10 sm:py-12 border rounded">
    <Search className="mx-auto text-gray-400 w-16 h-16 mb-4" />
    <h3 className="text-lg font-semibold text-red-800 mb-2">Ocorreu um erro</h3>
    <p className="text-gray-600">Não foi possível fazer a pesquisa.</p>
    <p className="text-gray-600">Tenta novamente mais tarde.</p>
  </div>
);

function ResultGroup({ items }) {
  return (
    <ul className="space-y-2 mb-2">
      {items.map((item, index) => (
        <Form reloadDocument action={ROUTES.RADAR} method="post" key={index}>
          <input type="hidden" name="name" value={item.name} />
          <input type="hidden" name="lat" value={item.coords.lat} />
          <input type="hidden" name="lon" value={item.coords.lon} />
          <button
            type="submit"
            className="flex w-full items-center bg-gray-50 hover:bg-gray-200 rounded-sm py-4 pl-3 border cursor-pointer"
          >
            <MapPin className="mr-3 text-gray-400 " strokeWidth={2} size={24} />

            <span className="font-semibold text-gray-800">{item.name}</span>
          </button>
        </Form>
      ))}
    </ul>
  );
}

type Params = {
  locationName: string;
  size?: "small" | "normal";
};

export default function RadarLocationSearchModal({ locationName, size = "normal" }: Params) {
  const fetcher = useFetcher();

  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, SEARCH_DELAY);
  const isLoading = fetcher.state !== "idle";
  const [results, setResults] = useState(RESULTS_EMPTY_STATE);
  const [hasSearched, setHasSearched] = useState(false);

  function clearModal() {
    setIsOpen(false);

    setHasSearched(false);
    setSearchTerm("");
    setResults(RESULTS_EMPTY_STATE);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 800);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    function handleEscape(event) {
      if (event.key === "Escape") {
        clearModal();
      }
    }

    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setHasSearched(true);

      fetcher.submit({ query: debouncedSearchTerm }, { action: API_ROUTES.LOCATION_SEARCH, method: "post" });
    } else {
      setResults(RESULTS_EMPTY_STATE);
      setHasSearched(false);
    }
  }, [debouncedSearchTerm]);

  // Add a new useEffect to handle fetcher state changes
  useEffect(() => {
    if (fetcher.state === "idle" && fetcher.data) {
      if (fetcher.data?.error) {
        console.error("Error fetching search results:", fetcher.data?.error);

        setResults(RESULTS_EMPTY_STATE);
        return;
      }

      setResults(fetcher.data);
    }
  }, [fetcher.state, fetcher.data]);

  const hasResults = results.length > 0;

  const Trigger = size === "small" ? SmallTrigger : NormalTrigger;

  return (
    <>
      <Trigger onClick={openModal} locationName={locationName} />

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-1 sm:p-4 ">
          <div
            className={`bg-white text-left text-base text-gray-900 rounded overflow-y-auto ${
              isMobile ? "w-full h-full" : "w-full max-w-2xl max-h-[90vh]"
            }`}
          >
            <div className="p-4 sm:p-6 ">
              <div className="flex justify-between items-start mb-6">
                <div>
                  <h2 className="text-2xl font-bold mb-4">Encontra espetáculos perto de ti</h2>
                  <p className="text-sm text-gray-500">
                    Vamos mostrar-te os espetáculos que vão acontecer nas salas mais próximas da localização que
                    escolheres.
                  </p>
                </div>
                <button onClick={clearModal} className="text-gray-500 hover:text-gray-700">
                  <X size={24} />
                </button>
              </div>

              <div className="relative mb-6">
                <input
                  type="text"
                  placeholder="Pesquisa pelo nome da tua cidade"
                  className="w-full py-2 pl-10 pr-4 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  autoFocus={!isMobile}
                  disabled={isLoading}
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              </div>

              {isLoading ? (
                <LoadingState />
              ) : !hasSearched ? (
                <EmptyState />
              ) : !hasResults ? (
                <NoResultsState />
              ) : (
                <div>
                  <ResultGroup items={results} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

type TriggerParams = {
  onClick: MouseEventHandler;
  locationName: string;
};

const SmallTrigger = ({ onClick, locationName }: TriggerParams) => (
  <button onClick={onClick} className={`underline flex flex-row items-center gap-1 text-gray-800 text-xl font-bold`}>
    <MapPin className="w-4 h-4 " />
    {locationName}
    <ChevronDown className="w-4 h-4" />
  </button>
);

const NormalTrigger = ({ onClick, locationName }: TriggerParams) => (
  <button
    onClick={onClick}
    className="text-white mx-auto text-center text-xl md:text-4xl font-base underline underline-offset-4 flex flex-row items-center gap-2"
  >
    <MapPin className="w-6 h-6 md:w-6 md:h-6 text-red-200" />
    {locationName}
    <ChevronDown className="w-6 h-6 md:w-6 md:h-6 text-red-200" />
  </button>
);
